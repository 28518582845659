<template>
  <div>
    <el-row class="seach-box">
      <!-- 搜索栏 -->
      <el-col style="display: flex;align-items: center;justify-content: flex-start;">
        <el-form  size="mini" label-width="85px" @submit.native.prevent>
          <el-form-item label="坐标名称：" size="mini">
            <el-input class="input-style" v-model="query.name" type="text" placeholder="请输入坐标名称"></el-input>
          </el-form-item>
        </el-form>
        <div style="margin-left: 10px;">
          <el-button size="mini" type="primary"  @click="searchChange">搜索</el-button>
          <el-button size="mini" type="warning" @click="rest">重置</el-button>
          <el-button size="mini" type="primary" icon="el-icon-plus" @click="add">新增</el-button>
        </div>
      </el-col>
    </el-row>
      <!-- 列表栏 -->
    <el-row style="margin-top: 10px;">
      <el-col style="background-color: #FFFFFF;">
        <el-table :data="tableList">
          <el-table-column label="坐标名称" align="center" prop="name"/>
          <el-table-column label="经度" align="center" prop="longitude"/>
          <el-table-column label="纬度" align="center" prop="latitude" />
          <el-table-column label="创建时间" align="center" prop="createTime"/>
          <el-table-column label="操作"  align="center">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="handleUpdata(scope.row)">修改</el-button>
              <el-button size="mini" type="danger"  @click="handleDelete(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
        v-if="total>0"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageNum"
        :page-size="query.pageSize"
        layout="total,sizes,  prev, pager, next, jumper"
        :total="total">
        </el-pagination>
      </el-col>
    </el-row>
    <!-- 增加修改弹窗 -->
    <el-dialog :visible.sync="open" :title="title">
      <el-form ref="form" :model="form" :rules="rules">
        <el-row>
          <el-col style="margin-bottom: 20px;">
            <el-form-item label="坐标名称：" prop="name" style="display: flex;align-items: center;justify-content: flex-start;">
              <el-input type="text" style="width: 300px;" v-model="form.name" placeholder="请输入坐标名称"/>
            </el-form-item>
          </el-col>
          <el-col style="margin-bottom: 20px;">
            <el-form-item label="经纬度：" prop="longitude" style="display: flex;align-items: center;justify-content: flex-start;">
              <el-input v-model="form.longitude" style="width: 150px"></el-input>-
              <el-input v-model="form.latitude" style="width: 150px"></el-input>
              <!-- <span> 点击地图获取经纬度</span> -->
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item>
              <!-- 地图组件 -->
              <TMap ref="child" v-bind:form="form" @map-confirm="confirmLocation" @getPlace="getPlace" v-if="open"></TMap>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div style="margin-top: 20px;">
        <el-button @click="resetForm('form')">取 消</el-button>
        <el-button type="primary" @click="submitForm('form')">提 交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { getAuthenticateList, addAuthenticate, updateAuthenticate, delAuthenticate } from '@/api/training'
  import { mapMutations } from 'vuex'
  import TMap from '../uplode/TMap.vue'
  export default {
    name: 'Dictionaries',
    components: {
      TMap
    },
    data() {
        return {
          // 坐标数据列表
          tableList: [],
          // 增加修改弹窗
          open: false,
          // 弹窗标题
          title: null,
          // 总条数
          total: 0,
          // 搜索条件
          query: {
            name: null,
            pageNum: 1,
            pageSize: 10
          },
          // 提交的表单
          form: {
            latitude: 21.43527,
            longitude: 109.191269
          },
          // 表单校验规则
          rules: {
            name: [{ required: true, message: '不能为空', trigger: 'blur' }],
            longitude: [{ required: true, message: '不能为空', trigger: 'change' }]
          }
        }
    },
    created() {
      // 创建时调用获取现有坐标列表数据
      this.getAuthenticateList()
    },
    methods: {
      ...mapMutations({
        saveUser: 'SAVE_USER'
      }),
      // 点击地图获取经纬度
      getPlace (e) {
        console.log(e)
        this.longitudeLatitude = e
        this.$set(this.form, 'longitude', e.lng)
        this.$set(this.form, 'latitude', e.lat)
      },
      confirmLocation() {
        const e = this.longitudeLatitude
        console.log(e)
        console.log(this.longitudeLatitude)
        this.stadiumData.latitude = e.lat
        this.stadiumData.longitude = e.lng
        this.showMap = false
      },
      // 搜索按钮
      searchChange() {
        this.rest()
        this.getAuthenticateList()
        console.log('搜索事件点击')
      },
      // 重置按钮
      rest() {
        this.query.name = null
        this.form = {
          latitude: 21.43527,
          longitude: 109.191269
        }
        this.query.pageNum = 1
        this.open = false
        this.getAuthenticateList()
      },
      // 添加按钮
      add() {
        this.rest()
        this.open = true
        this.title = '添加坐标'
      },
      // 修改
      handleUpdata(row) {
        const data = {
          id: row.id,
          name: row.name,
          latitude: row.latitude,
          longitude: row.longitude
        }
        this.form = data
        this.open = true
      },
      // 删除
      handleDelete(row) {
        console.log(row)
        const id = row.id
        console.log(id)
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          delAuthenticate(id).then(res => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getAuthenticateList()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
          this.getAuthenticateList()
        })
      },
      // 获取坐标列表数据接口
      getAuthenticateList() {
        getAuthenticateList(this.query).then(res => {
          this.tableList = res.data.data.rows
          this.total = res.data.data.total
        })
      },
      // 提交按钮事件
      submitForm(form) {
        console.log(form)
        const id = this.form.id
        this.$refs[form].validate((valid) => {
          if (valid) {
            if (!id) {
              addAuthenticate(this.form).then(res => {
                if (res.data.code === '500') {
                  console.log(res.data)
                  this.$message.error(res.data.msg)
                } else {
                  console.log(res)
                    this.$message({
                      message: '添加成功',
                      type: 'success'
                    })
                  this.open = false
                  this.getAuthenticateList()
                }
              })
            } else {
              console.log(this.form)
              updateAuthenticate(this.form).then(res => {
                this.$message({
                  message: '修改成功',
                  type: 'success'
                })
                this.open = false
                this.getAuthenticateList()
              })
            }
          }
        })
      },
      // 弹窗取消按钮
      resetForm(form) {
        this.rest()
      },
      // 分页数据 -- 单页数据量
      handleSizeChange(e) {
        this.pageSize = e
        this.getAuthenticateList()
      },
      // 页码点击
      handleCurrentChange(e) {
        this.pageNum = e
        this.getAuthenticateList()
      }
    }
  }
</script>

<style>
  .seach-box{
    padding: 10px;
    background-color: #FFFFFF;
  }
  .input-style{
    width: 150px;
  }
  .el-form-item {
    margin-bottom: 0 !important;
  }
</style>
